import http from "../../services/http";
import { handleErrors } from "../../helpers/error";

const path = "/cms/quiz_bank";
const path2 = "/cms/quiz_bank/full_quizbank_detail/";
const path3 = "/cms/quiz_bank_qa/full_qa_detail/";
const path4 = "/cms/quiz_bank_record";
const path5 = "/cms/quiz_bank_record/user_record";
const category = "/cms/quiz_bank/rich_categories";
const year = "/year";
const board = "/board";
const subject = "/cms/quiz_bank/rich_courses/";
const fullQuizBank = "/cms/quiz_bank/full_quizbank_detail";
const lastResult ="/cms/quiz_bank_record/user_result"
export const loadCategory = ({ body = {}, onSuccess, onError, onEnd } = {}) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.get({ url: category });
      console.log("res=======> ", res);

      if (onSuccess) onSuccess(res);
    } catch (err) {
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const loadQuizResult = ({
  body = {},
  onSuccess,
  onError,
  onEnd,
  user = "",
  id = "",
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url: path5 + `?user=${user}&quizBankQA=${id}`,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const loadLastQuizResult = ({
  body = {},
  onSuccess,
  onError,
  onEnd,
  user = "",
  id = "",
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url: lastResult + `?user=${user}&quizBankQA=${id}`,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const Select_year = ({ onSuccess, onError, onEnd } = {}) => {
  return async () => {
    try {
      const res = await http.get({
        url: year,
      });
      if (onSuccess) onSuccess(res);
    } catch (err) {
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const Select_Board = ({ onSuccess, onError, onEnd } = {}) => {
  return async () => {
    try {
      const res = await http.get({
        url: board,
      });
      if (onSuccess) onSuccess(res);
    } catch (err) {
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const Select_Subject = ({ onSuccess, onError, onEnd, id = "" } = {}) => {
  return async () => {
    try {
      const res = await http.get({
        url: subject + id,
      });
      if (onSuccess) onSuccess(res);
    } catch (err) {
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const loadFullQuizBank = ({
  body = {},
  onSuccess,
  onError,
  onEnd,
  section = "",
  year = "",
  board = "",
  subject = "",
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url:
          fullQuizBank +
          `?course=${subject}&year=${year}&board=${board}&section=${section}`,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const postRecord = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch, getState) => {
    try {
      const res = await http.post({
        url: path4,
        body,
      });
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const loadQuizBank = ({ body = {}, onSuccess, onError, onEnd } = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url: path,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const loadQA = ({
  body = {},
  id = "",
  onSuccess,
  onError,
  onEnd,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url: path2 + id,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const loadQAMcqs = ({
  body = {},
  id = "",
  onSuccess,
  onError,
  onEnd,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const { entities, auth } = store;
      //showing loader on ui
      const res = await http.get({
        url: path3 + id,

        headers: {
          "x-auth-token": auth.token,
        },
      });

      //onSuccess event firing
      if (onSuccess) onSuccess(res);
    } catch (err) {
      //onError event firing
      console.log("Error => ", err);
      if (onError) onError(err);
      else handleErrors(err);
    } finally {
      if (onEnd) onEnd();
    }
  };
};