import Logo from "../assets/temp/logo.png";

export const allSetting = [
  {
    siteLogo: Logo,
    siteName: "Outclass",
    sitePhoneNumber: "123 456 7890",
    siteEmailAddress: "demo@mail.com",
    siteWhatsappNumber: "123 456 7890",
    siteFacebookUrl: "#",
    siteInstagramUrl: "#",
    siteTwitterUrl: "#",
    sitePinterestUrl: "#",
  },
];
