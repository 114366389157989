import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  state: false,
  options: {},
  saveData: [],  
  option:false,
  page:0,
  exit:false,
  numberOfPages:0,
};

const slice = createSlice({
  name: "saveOption",
  initialState,
  reducers: {
    setOptionState:(state,action)=>{
      state.option = action.payload;
    },
    setExitState:(state,action)=>{
      state.exit = action.payload;
    },
    setPage:(state,action)=>{
      state.page = action.payload;
    },
    setTotalPages:(state,action)=>{
      state.numberOfPages = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setOption: (state, action) => {
      state.options = action.payload;
    },
    saveOptions: (state, action) => {
      state.saveData = action.payload;
    },
  },
});

export const { saveOptions, setOption, setState, setOptionState,setPage,setTotalPages,setExitState} = slice.actions;
export default slice.reducer;
